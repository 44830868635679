var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.cartItems,"hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.itemName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/items/" + (item.itemId))}},[_c('div',{staticClass:"text-body-2 text-md-body-1"},[_c('b',[_vm._v(_vm._s(item.baseItem.name))]),_c('div',[_c('b',[_vm._v(_vm._s(_vm.handleNullProperty(item.category)))])])])])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('v-form',{ref:"form",staticClass:"pt-5"},[_c('v-text-field',{staticClass:"numberInput",staticStyle:{"width":"40%"},attrs:{"outlined":"","dense":"","type":"number","rules":_vm.quantityRules,"value":item.quantity},on:{"change":function($event){return _vm.changeQuantity($event, item)}}})],1)]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap text-body-2 text-md-body-1",staticStyle:{"cursor":"default"}},[_c('b',[_vm._v(_vm._s(item.baseItem.price)+" "+_vm._s(_vm.$t("cartPage.currency"))+"/"+_vm._s(_vm.$i18n.locale == "ar" ? item.uom.arName : item.uom.name))])])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap text-body-2 text-md-body-1",staticStyle:{"cursor":"default"}},[_c('b',[_vm._v(_vm._s(item.baseItem.price * item.quantity)+" "+_vm._s(_vm.$t("cartPage.currency")))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var i = ref.i;
return [_c('div',{attrs:{"md":"2","cols":"2"}},[_c('v-btn',{staticStyle:{"letter-spacing":"0px"},attrs:{"loading":_vm.cartLoading,"icon":""},on:{"click":function($event){return _vm.removeFromCart(item, i)}}},[_c('v-icon',[_vm._v("mdi-close-circle-outline")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
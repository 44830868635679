<template>
  <div>
    <v-data-table
      dense
      :headers="headers"
      :items="cartItems"
      hide-default-footer
      :items-per-page="-1"
    >
      <template v-slot:item.itemName="{ item }">
        <router-link :to="`/items/${item.itemId}`">
          <div class="text-body-2 text-md-body-1">
            <b>{{ item.baseItem.name }}</b>
            <div>
              <b>{{ handleNullProperty(item.category) }}</b>
            </div>
          </div>
        </router-link>
      </template>
      <template v-slot:item.quantity="{ item }">
        <v-form ref="form" class="pt-5">
          <v-text-field
            outlined
            class="numberInput"
            dense
            type="number"
            :rules="quantityRules"
            @change="changeQuantity($event, item)"
            style="width: 40%"
            :value="item.quantity"
          ></v-text-field>
        </v-form>
      </template>
      <template v-slot:item.price="{ item }">
        <div class="text-no-wrap text-body-2 text-md-body-1" style="cursor: default">
          <b
            >{{ item.baseItem.price }} {{ $t("cartPage.currency") }}/{{
              $i18n.locale == "ar" ? item.uom.arName : item.uom.name
            }}</b
          >
        </div>
      </template>
      <template v-slot:item.total="{ item }">
        <div class="text-no-wrap text-body-2 text-md-body-1" style="cursor: default">
          <b>{{ item.baseItem.price * item.quantity }} {{ $t("cartPage.currency") }}</b>
        </div>
      </template>
      <template v-slot:item.actions="{ item, i }">
        <div md="2" cols="2">
          <v-btn
            style="letter-spacing: 0px"
            @click="removeFromCart(item, i)"
            :loading="cartLoading"
            icon
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "ItemCard",
  props: ["cartItems"],
  data() {
    return {
      hover: false,

      quantityRules: [
        (v) => !isNaN(v) || "",
        (v) => v >= 1 || "",
        (v) => v <= 9999999999 || "",
      ],
      cartLoading: false,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("cartPage.itemCol"),
          align: "start",
          value: "itemName",
        },
        { text: this.$t("cartPage.quantityCol"), value: "quantity" },
        { text: this.$t("cartPage.priceCol"), value: "price" },
        { text: this.$t("cartPage.totalCol"), value: "total" },
        { text: "", value: "actions" },
      ];
    },
  },
  methods: {
    handleNullProperty(val) {
      if (val == null) return "N/A";
      else return val.name;
    },
    removeFromCart(item, i) {
      this.cartLoading = i;
      this.$store
        .dispatch("itemsModule/removeFromCart", { id: item.itemId })
        .then(() => {
          this.$buefy.toast.open({
            message: this.$t("itemsPage.removeCartItemNotif"),
            type: "is-success",
          });
          this.$emit("removedItem");
          this.getCount();
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: this.$t("app.errorNotif"),
            type: "is-danger",
          });
        })
        .finally(() => {
          this.cartLoading = false;
        });
    },
    getCount() {
      this.$store.dispatch("cartModule/getCount", {});
    },
    changeQuantity(val, item) {
      var isValid = this.$refs.form.validate();
      if (isValid) {
        this.$store
          .dispatch("cartModule/updateItem", {
            id: item.id,
            body: {
              quantity: val,
            },
          })
          .then(() => {
            this.$emit("refresh");
          })
          .catch(() => {
            this.$buefy.toast.open({
              message: this.$t("app.errorNotif"),
              type: "is-danger",
            });
          });
      }
    },
    getItemName(item) {
      return item.baseItem?.displayName == null
        ? item.baseItem?.name
        : item.baseItem?.displayName;
    },
  },
};
</script>

<style scoped>
.media {
  border-radius: 3%;
}
.media:hover {
  box-shadow: 0 0 3pt 2pt #000000;
  border-radius: 3%;
  cursor: pointer;
}
.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  width: 100%;
  position: absolute;
}
.block {
  display: block !important;
  height: 24px;
}
</style>
